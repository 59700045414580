import { gql } from "apollo-boost";

const queries = {
    LOGIN: gql`
        query Login($username: String!, $password: String!) {
            login(input: { username: $username, password: $password }) {
                userId
                token
                name
                type
            }
        }
    `,
    CHANGE_PASSWORD: gql`
        mutation ChangePassword($password: String!, $currentPassword: String!) {
            changePassword(
                password: $password
                currentPassword: $currentPassword
            ) {
                _id
            }
        }
    `,
    EDIT_USER_PROFILE: gql`
        mutation EditUserProfile(
            $email: String
            $name: String
            $phone: String
        ) {
            editUserProfile(
                input: { name: $name, email: $email, phone: $phone }
            ) {
                _id
            }
        }
    `,
    CREATE_ORDER: gql`
        mutation CreateOrder(
            $adultPax: Int!
            $childPax: Int!
            $childnobedPax: Int!
            $singlePax: Int!
            $infantPax: Int!
            $price: Float!
            $tour: ID!
            $tourDate: String!
            $slots: Int!
            $members: [AddMembersInput!]!
            $receipt: String
            $phone: String
            $email: String
        ) {
            createOrder(
                input: {
                    adultPax: $adultPax
                    childPax: $childPax
                    childnobedPax: $childnobedPax
                    infantPax: $infantPax
                    price: $price
                    tour: $tour
                    tourDate: $tourDate
                    singlePax: $singlePax
                    slots: $slots
                    members: $members
                    receipt: $receipt
                    phone: $phone
                    email: $email
                }
            ) {
                _id
            }
        }
    `,
    FETCH_ALL_AREAS: gql`
        query FetchAllAreas {
            fetchAllAreas {
                _id
                name
            }
        }
    `,
    FETCH_ALL_COUNTRIES: gql`
        query FetchAllCountries {
            fetchAllCountries {
                _id
                name
            }
        }
    `,
    FETCH_ALL_STATES: gql`
        query FetchAllStates($country: String) {
            fetchAllState(filter: { country: $country }) {
                _id
                name
            }
        }
    `,
    FETCH_AIRLINE: gql`
        query FetchAirline {
            fetchAirlines {
                _id
                name
                img
            }
        }
    `,
    FETCH_TOURS: gql`
        query FetchPackages(
            $limit: Int!
            $page: Int!
            $state: String
            $package: String
            $country: String
        ) {
            fetchPackagesAgent(
                pagination: { limit: $limit, page: $page }
                filters: { state: $state, package: $package, country: $country }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    cnName
                    image
                    itinerary
                    video
                    assemblyTime
                    meetupPoint
                    tourguideName
                    tourguideChineseName
                    tourguidePhone
                    handcarryWeight
                    luggageWeight
                    deposit
                    packageCode
                    savings
                    status
                    country
                    state
                    tours {
                        _id
                        status
                        childPrice
                        adultPrice
                        infantPrice
                        tourDate
                        tourCode
                        childNoBedPrice
                        singlePrice
                        slots
                        breakdown {
                            groundPackage
                            supp
                            airticket
                            tipping
                            insurance
                            visa
                            childGroundPackage
                            childSupp
                            childAirticket
                            childTipping
                            childInsurance
                            childVisa
                            childNoBedAirticket
                            childNoBedSupp
                            childNoBedGroundPackage
                            childNoBedTipping
                            childNoBedInsurance
                            childNoBedVisa
                            singleGroundPackage
                            singleSupp
                            singleAirticket
                            singleTipping
                            singleInsurance
                            singleVisa
                            ground
                        }
                        flights {
                            type
                            airway
                            code
                            startCity
                            endCity
                            startTime
                            endTime
                            transitStartCity
                            transitEndCity
                            transitStartTime
                            transitEndTime
                            _id
                        }
                        amenities {
                            insurance
                            wifi
                            group
                            tax
                            checkin
                            tourleader
                            hotel
                            meals
                            gratuties
                            fullpayment
                            accessible
                            facilities
                            pool
                            driver
                            allin
                        }
                    }
                    attributes {
                        attribute
                        value
                        _id
                    }
                     gallery {
                        _id
                        img
                     }
                }
            }
        }
    `,
    FETCH_ORDERS: gql`
        query FetchOrders(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $status: String
            $country: String
            $state: String
        ) {
            fetchOrdersAgent(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: { status: $status, country: $country, state: $state }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    members {
                        designation
                        name
                        dob
                        passport
                        phone
                        remark
                    }
                    tour {
                        _id
                        package {
                            _id
                            name
                        }
                        tourCode
                        tourDate
                        status
                    }
                    slots
                    childPax
                    adultPax
                    childnobedPax
                    singlePax
                    status
                    price
                    discount
                    createdBy {
                        _id
                        name
                    }
                    createdAt
                    receipt
                }
            }
        }
    `,
    FETCH_SALES_RANKING_PERCENTAGE: gql`
        query FetchAgentSalesRankingPercentage(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $dateFrom: String
            $dateTo: String
            $status: String
        ) {
            fetchAgentSalesRankingPercentage(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    member {
                        _id
                        name
                    }
                    commission
                    status
                    position
                }
            }
        }
    `,
    FETCH_VIDEOS: gql`
        query FetchVideos($limit: Int!, $page: Int!) {
            fetchVideos(pagination: { limit: $limit, page: $page }) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    url
                }
            }
        }
    `,
    FETCH_BANNERS_AGENT: gql`
        query FetchBannersAgent($state: String!, $type: String!) {
            fetchBannersAgent(filter: { state: $state, type: $type }) {
                _id
                name
                url
                type
            }
        }
    `,
    FETCH_CAMPAIGNS_AGENT: gql`
        query FetchCampaignsAgent {
            fetchCampaignsAgent {
                _id
                name
                url
                type
                enNotesArray
                cnNotesArray
            }
        }
    `,
    FETCH_CASES_SALES_RANKING: gql`
        query FetchToursSalesRanking(
            $limit: Int!
            $page: Int!
            $package: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $rank: ID
            $state: ID
            $status: String
        ) {
            fetchToursSalesRanking(
                pagination: { limit: $limit, page: $page }
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    rank: $rank
                    sate: $state
                }
            ) {
                pagination {
                    documents
                    page
                    limit
                    totalPages
                }
                data {
                    ranking
                    user
                    commission
                }
            }
        }
    `,
    WHOAMI: gql`
        query Whoami {
            whoami {
                name
                username
                email
                phone
                createdAt
                address
                city
                postal
                state
                country
                type
            }
        }
    `,
    FETCH_USER_PAYMENT_VOUCHERS: gql`
        query FetchUserPaymentVouchers(
            $limit: Int!
            $page: Int!
            $dateFrom: String
            $dateTo: String
            $calculatePoints: String
        ) {
            fetchUserPaymentVouchers(
                pagination: { limit: $limit, page: $page }
                filters: {
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    calculatePoints: $calculatePoints
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    payee {
                        _id
                        name
                        nickname
                        code
                    }
                    date
                    method
                    batch
                    status
                    calculatePoints
                    item {
                        amount
                        address
                    }
                    item2 {
                        amount
                        address
                    }
                    item3 {
                        amount
                        address
                    }
                    item4 {
                        amount
                        address
                    }
                    item5 {
                        amount
                        address
                    }
                    orderNo
                }
                totals {
                    totalPaymentVoucher
                    totalVoucherAmount
                }
            }
        }
    `,
    CREATE_CASE: gql`
        mutation createTour(
            $package: ID!
            $tourCode: String!
            $childPrice: Float
            $tourDate: String
            $adultPrice: Float
            $childNoBedPrice: Float
            $singlePrice: Float
            $slots: Float
        ) {
            createTour(
                input: {
                    package: $package
                    tourCode: $tourCode
                    childPrice: $childPrice
                    adultPrice: $adultPrice
                    childNoBedPrice: $childNoBedPrice
                    singlePrice: $singlePrice
                    infantPrice: $infantPrice
                    tourDate: $tourDate
                    slots: $slots
                }
            ) {
                _id
            }
        }
    `,
    FETCH_CASES_REPORT: gql`
        query FetchToursAgent(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
            $downline: ID
        ) {
            fetchToursAgent(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    downline: $downline
                }
            ) {
                totals {
                    tours
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                    totalPtpReferralComm
                    totalPtp2ReferralComm
                    totalPtp1ReferralComm
                    totalGroupNetGdv
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    members {
                        _id
                        commission
                        newCommission
                        member {
                            _id
                            name
                        }
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    unitNumber
                    sponsorCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    amenities {
                        insurance
                        wifi
                        group
                        tax
                        checkin
                        tourleader
                        hotel
                        meals
                        gratuties
                        fullpayment
                        accessible
                        facilities
                        pool
                        driver
                        allin
                    }
                    leaderCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    createdBy {
                        _id
                        name
                    }
                    package {
                        _id
                        name
                    }
                }
            }
        }
    `,
    FETCH_EVENTS_AGENT: gql`
        query FetchEventsAgent {
            fetchEventsAgent {
                start
                end
                title
                start_time
                end_time
                location
                image
            }
        }
    `,
    FETCH_DOWNLINES: gql`
        query FetchDownlines(
            $limit: Int!
            $page: Int!
            $name: String
            $referral: String
            $status: String
            $rank: ID!
        ) {
            fetchDownlines(
                filters: {
                    name: $name
                    referral: $referral
                    status: $status
                    rank: $rank
                }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                totals {
                    personalSalesTotal
                    groupSalesTotal
                }
                uplineFilterList {
                    value
                    label
                    id
                }
                data {
                    _id
                    status
                    name
                    personalSales
                    groupSales
                    ranking {
                        _id
                        name
                    }
                    referral {
                        _id
                        name
                    }
                    secondReferral {
                        _id
                        name
                    }
                }
            }
        }
    `,
    FETCH_DOWNLINE_SALES_REPORT_AGENT: gql`
        query FetchDownlineSalesReportAgent(
            $dateTo: String
            $dateFrom: String
        ) {
            fetchDownlineSalesReportAgent(
                filter: { dateTo: $dateTo, dateFrom: $dateFrom }
            ) {
                data {
                    user
                    personalBookingSales
                    convertUnit
                    convertPercentage
                    personalNetGdv
                    convertNetGdv
                    netGdvPercentage
                    groupBookingSales
                    groupConvertUnit
                    groupConvertPercentage
                    groupPersonalNetGdv
                    groupConvertNetGdv
                    groupNetGdvPercentage
                    salesRankingCommission
                }
            }
        }
    `,
    UPLOAD_PAYMENT: gql`
    mutation UploadPayment($img: String!, $packageId: String!) {
        uploadPayment(input: { img: $img, packageId: $packageId }) {
            _id
        }
    }
`,
    GENERATE_IK_SIGNATURE: gql`
        query {
            generateImageKitSignature {
                signature
                token
                expire
            }
        }
    `,
};

export default queries;

import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import queries from "../helpers/queries";
import { useForm, Controller } from "react-hook-form";
import { withUser } from "../Contexts/user";
import Table from "../Components/table";
import { FiUser, FiFile, FiPlus } from "react-icons/fi";
import Select from "react-select";
import moment from "moment";
import Modal from "../Components/modal";
import { Country, State } from "country-state-city";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import axios from "axios";

const statusOptions = [
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
];

const RiskyDownlines = withRouter(() => {
    let CountryList = [Country.getCountryByCode("JP"), Country.getCountryByCode("KR"), Country.getCountryByCode("CN"), Country.getCountryByCode("TW"), Country.getCountryByCode("IN"), Country.getCountryByCode("TH"), Country.getCountryByCode("VN")]
    const [modalData, setModalData] = useState({});
    const [galleryModalState, setGalleryModalState] = useState(false);
    const [departureTableData, setDeparturesTableData] = useState([]);
    const [departureTablePaginationState, setDepartureTablePaginationState] =
        useState({
            limit: 10,
            page: 1,
        });
    const [editMemberState, setEditMemberState] = useState(false);

    const {
        handleSubmit: handleFilterSubmit,
        control,
        errors,
    } = useForm({
        defaultValues: {
            name: "",
            referral: null,
        },
    });

    const apollo = useApolloClient();


    const {
        register: galleryRegister,
        errors: galleryErrors,
        handleSubmit: handleGallerySubmit,
        getValues: getGalleryValues,
        reset: resetGallery,
    } = useForm({
        defaultValues: {
            image: "",
        },
    });

    const paginateData = (array, { page, limit }) => {
        let updatedArray;
        let currentIndex = (page - 1) * limit;

        updatedArray = [...array].slice(currentIndex, currentIndex + limit);

        return updatedArray;
    };

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const [filters, setFilters] = useState({
        status: undefined,
    });

    const generateSignature = async () => {
        const ikSigReq = await apollo.query({
            query: queries.GENERATE_IK_SIGNATURE,
            fetchPolicy: "no-cache",
        });

        return ikSigReq;
    };

    const [uploadPayment] = useMutation(queries.UPLOAD_PAYMENT);

    const handleAddGallery = async () => {
        try {
            const formValues = getGalleryValues();

            let newImage = undefined;

            if (formValues.img.length !== 0) {
                const imageresult = await generateSignature();
                const imageFormData = new FormData();

                imageFormData.append("file", formValues.img[0]);
                imageFormData.append(
                    "publicKey",
                    "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
                );
                imageFormData.append(
                    "signature",
                    imageresult.data.generateImageKitSignature.signature
                );
                imageFormData.append(
                    "expire",
                    imageresult.data.generateImageKitSignature.expire
                );
                imageFormData.append(
                    "token",
                    imageresult.data.generateImageKitSignature.token
                );
                imageFormData.append(
                    "fileName",
                    `${formValues.img[0].name} - ${imageresult.data.generateImageKitSignature.expire}`
                );
                imageFormData.append("folder", "banners");

                const imageUploadResult = await axios.post(
                    "https://upload.imagekit.io/api/v1/files/upload",
                    imageFormData
                );

                newImage = imageUploadResult.data.url;
            }

            const result = await uploadPayment({
                variables: {
                    packageId: modalData._id,
                    img: newImage ? newImage : "",
                },
            });

            if (result.error) {
                alert("Failed");
            }

            if (result.data) {
                // do success stuff
                resetGalleryModal();
                refetchOrders();
                alert("Success");
            }
        } catch (err) {
            //do err stuff
            alert("Failed");
            console.log(err);
        }
    };

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            name: values.name !== "" ? values.name.value : filters.name,
            referral:
                values.referral !== null
                    ? values.referral.value
                    : filters.referral,
            status:
                values.status !== undefined
                    ? values.status.value
                    : filters.status,
            rankings:
                values.rankings !== undefined
                    ? values.rankings.value
                    : filters.rankings,
        }));

        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const columns = useMemo(
        () => [
            {
                Header: "Proof of Payment",
                accessor: "receipt",
                Cell: ({ row: { original }, value }) => (
                    <div className="flex justify-center cursor-pointer">
                         <div
                            className="cursor-pointer mr-2"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });
                                setGalleryModalState(true);
                            }}
                        >
                            <FiPlus />
                        </div>
                        {value ? (
                            <>
                                <FiFile
                                    onClick={() => {
                                        window.open(value, "_blank"); // Open image in a new tab if clicked
                                    }}
                                ></FiFile>
                            </>
                        ) : (
                            <></>
                        )}
                       
                    </div>
                ),
            },
            {
                Header: "Price",
                accessor: "price",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> RM {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Members",
                id: "members",
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="flex justify-center cursor-pointer"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });

                                setEditMemberState(true);
                            }}
                        >
                            <FiUser />
                        </div>
                    );
                },
            },
            {
                Header: "Total Pax",
                accessor: "slots",
            },
            {
                Header: "Adult Pax",
                accessor: "adultPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Child No Bed Pax",
                accessor: "childnobedPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Child Pax",
                accessor: "childPax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Single Pax",
                accessor: "singlePax",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> {value}</div>;
                    }

                    return <div>0</div>;
                },
            },
            {
                Header: "Order Date",
                accessor: "createdAt",
                Cell: ({ value }) => (
                    <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                ),
            },
            {
                Header: "Created By",
                accessor: "createdBy",
                Cell: ({ value }) => {
                    if (value) {
                        return <div className="capitalize">{value.name}</div>;
                    }

                    return <div></div>;
                },
            },
        ],
        []
    );

    const mobileColumns = useMemo(
        () => [
          
            {
                Header: "Price",
                accessor: "price",
                Cell: ({ value }) => {
                    if (value) {
                        return <div> RM {value}</div>;
                    }

                    return <div></div>;
                },
            },
            {
                Header: "Members",
                id: "members",
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="flex justify-center cursor-pointer"
                            onClick={async () => {
                                setModalData({
                                    ...original,
                                });

                                setEditMemberState(true);
                            }}
                        >
                            <FiUser />
                        </div>
                    );
                },
            },
            {
                Header: "Total Pax",
                accessor: "slots",
            },
            {
                Header: "Order Date",
                accessor: "createdAt",
                Cell: ({ value }) => (
                    <div>{moment(parseInt(value)).format("YYYY-MM-DD")}</div>
                ),
            },
            {
                Header: "Payment",
                accessor: "receipt",
                Cell: ({ value }) => (
                    <div
                        className="flex justify-center cursor-pointer"
                        onClick={() => {
                            window.open(value, "_blank");
                        }}
                    >
                        <FiFile />
                    </div>
                ),
            },
        ],
        []
    );

    const mobileMemberColumns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            }
        ],
        []
    );

    const memberColumns = useMemo(
        () => [
            {
                Header: "Designation",
                accessor: "designation",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Date of Birth",
                accessor: "dob",
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {moment(parseInt(value)).format("YYYY-MM-DD")}
                    </div>
                ),
            },
            {
                Header: "Passport",
                accessor: "passport",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Phone",
                accessor: "phone",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Type",
                accessor: "type",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                Header: "Remark",
                accessor: "remark",
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        []
    );

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const resetMemberModal = () => {
        setEditMemberState(false);
    };

    const resetGalleryModal = () => {
        setGalleryModalState(false);
        resetGallery();
    };

    const { data: downlinesData,       refetch: refetchOrders, } = useQuery(queries.FETCH_ORDERS, {
        fetchPolicy: "network-only",
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            by: paginationState.by ? paginationState.by : undefined,
            dir: paginationState.dir ? paginationState.dir : undefined,
            package: filters.package ? filters.package.value : undefined,
            date: filters.date,
            status: filters.status ? filters.status : undefined,
            unitNumber: filters.unitNumber,
            country: filters.country ? filters.country.value : undefined,
            state: filters.state ? filters.state.value : undefined,
        },
        onCompleted: ({ fetchOrdersAgent: { pagination } }) => {
            setPaginationState(pagination);
        },
    });

    const getAllCountries = () => {
        return CountryList.map((country) => ({
            value: country.isoCode, // Use isoCode as the value for the option
            label: country.name, // Use country name as the label for the option
        }));
    };

    const getStatesForCountry = (countryIsoCode) => {
        const states = State.getStatesOfCountry(countryIsoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
    };

    useEffect(() => {
        if (modalData.members && departureTablePaginationState.page) {
            const departures = modalData.members;
            setDeparturesTableData(
                paginateData(departures, departureTablePaginationState)
            );
        }
    }, [departureTablePaginationState, modalData.members]);

    return (
        <div>
            <div className="py-4 px-12 h-full">
                <div className="w-full lg:pr-4">
                    <form
                        className="grid grid-cols-1 lg:grid-cols-3 mb-4 col-gap-4 row-gap-4"
                        onSubmit={handleFilterSubmit(updateFilters)}
                    >
                        <div className="">
                            <Controller
                                as={<Select styles={filterSelectStyles} />}
                                name="status"
                                placeholder={"Status"}
                                control={control}
                                options={statusOptions}
                                value={filters.status}
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        status: val[0].value,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={getAllCountries()}
                                value={filters.country}
                                placeholder="Country"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        country: val,
                                    }))
                                }
                            />
                        </div>
                        <div className="">
                            <Select
                                styles={filterSelectStyles}
                                isSearchable={true}
                                options={
                                    filters.country
                                        ? getStatesForCountry(
                                              filters.country.value
                                          )
                                        : []
                                }
                                value={filters.state}
                                placeholder="State"
                                onChange={(val) =>
                                    setFilters((prev) => ({
                                        ...prev,
                                        state: val,
                                    }))
                                }
                            />
                        </div>
                        <div></div>
                    </form>
                </div>
                <div className="mt-6 table-header-actions flex justify-end items-start"></div>
                <Table
columns={window.innerWidth < 600 ? mobileColumns : columns}
                    data={
                        downlinesData ? downlinesData.fetchOrdersAgent.data : []
                    }
                    updatePagination={setPaginationState}
                    paginationState={paginationState}
                    pageCount={
                        downlinesData
                            ? downlinesData.fetchOrdersAgent.pagination
                                  .totalPages
                            : 0
                    }
                    totalCount={
                        downlinesData
                            ? downlinesData.fetchOrdersAgent.pagination
                                  .documents
                            : 0
                    }
                />
            </div>
            {editMemberState && (
                <Modal reset={resetMemberModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white p-6 rounded-md lg:min-w-65 min-w-full">
                            <div className="text-lynch mb-4">Members</div>
                            <Table
                            columns={window.innerWidth < 600 ? mobileMemberColumns : memberColumns}
                                data={departureTableData}
                                updatePagination={
                                    setDepartureTablePaginationState
                                }
                                paginationState={departureTablePaginationState}
                                pageCount={1}
                                totalCount={1}
                            />
                        </div>
                    </div>
                </Modal>
            )}
                        {galleryModalState && (
                <Modal reset={resetGalleryModal}>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="w-80vw lg:w-full panel bg-white p-6 rounded-md">
                            <div className="mb-4 text-3xl font-bold">
                                Upload Gallery
                            </div>
                            <form
                                onSubmit={handleGallerySubmit(handleAddGallery)}
                                className="lg:w-104"
                            >
                                <div>
                                    <div className="my-2 text-sm text-lynch font-medium">
                                        Image
                                    </div>
                                    <input
                                        className={`my-2 p-3 w-full rounded-lg block bg-cool-gray border font-semibold text-lynch text-xs ${
                                            galleryErrors.img
                                                ? "border-red-400"
                                                : "border-gray-300"
                                        }`}
                                        name="img"
                                        ref={galleryRegister()}
                                        type="file"
                                    />
                                    {galleryErrors.img && (
                                        <p className="text-red-400 text-xs">{`Image cannot be empty`}</p>
                                    )}
                                </div>
                                <button className="mt-4 py-3 text-sm rounded-lg w-full bg-buttonblue bg-opacity-75 hover:bg-opacity-100 transition-all duration-200 block text-white">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
});

export default withUser(RiskyDownlines);

import Packages from "./Pages/Packages";
import { FcBriefcase, FcBusinessman, FcBarChart } from "react-icons/fc";
import {
  FiImage,
} from "react-icons/fi";
import ChangePassword from "./Pages/ChangePassword";
import EditProfile from "./Pages/EditProfile";
import Dashboard from "./Pages/Dashboard";
import Order from "./Pages/Orders";
import Checkout from "./Pages/Checkout";

const Routes =  [
  {
    path: "/profile",
    name: "Profile",
    Icon: FcBusinessman,
    component: Dashboard,
  },
  {
    path: "/",
    name: "Packages",
    Icon: FcBriefcase,
    component: Packages,
  },
  {
    path: "/orders",
    name: "Order",
    Icon: FcBarChart,
    component: Order,
  },
  {
    path: "/checkout",
    name: "Checkout",
    Icon: FiImage,
    component: Checkout,
    hidden: true,
  },
  {
    path: "/changepassword",
    name: "Change Password",
    Icon: FiImage,
    component: ChangePassword,
    hidden: true,
  },
  {
    path: "/editprofile",
    name: "Edit Profile",
    Icon: FiImage,
    component: EditProfile,
    hidden: true,
  },
];


export default Routes;
import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Image = styled.img`
    object-fit: cover;
    width: 100vw;
    height: 100%;
    ${mobile({ height: "30vh" })}
    ${tablet({ height: "100%" })}
`;

const Header = styled.div`
    height: 45vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    ${mobile({ height: "30vh" })}
    ${tablet({ height: "50vh" })}
`;

const BannerOverlay = (imageUrl) => {
    return (
        <Header>
            <Image src={imageUrl.imageUrl} alt="About"></Image>{" "}
        </Header>
    );
};

export default BannerOverlay;

import React from "react";
import Sidebar from "./sidebar";
import Topbar from "./topbar";
import Header from "./header";

const Layout = (props) => {
  return (
    <main>
      <div className="lg:hidden"><Header /></div>
      <div className="z-30 flex flex-auto h-full w-full relative min-h-screen">
        <Sidebar />
        <div className="wrapper w-full lg:w-10/12 flex flex-col">
          <Topbar className="flex-grow-0" />
          <div className="content bg-catskill w-full flex-grow overflow-auto">
            {props.children}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;

import React from "react";
import Routes from "../Routes";
import { Link, withRouter } from "react-router-dom";
import { withUser } from "../Contexts/user";
import { FcUndo } from "react-icons/fc";

const Sidebar = withRouter(({ user, location }) => {
    return (
        <div className="hidden lg:block sidebar px-2 bg-black min-h-screen text-white min-w-48 w-2/12 flex-shrink-0">
            <div className="flex justify-center h-48 w-48 mx-auto overflow-hidden rounded-full border-8 my-2 mb-12">
                <img
                    className="w-full h-full object-cover object-top"
                    src="https://ik.imagekit.io/dnddecpho/longrich/longrichlogo_COW-11Ynm.jpg?updatedAt=1707998703776"
                    alt={""}
                />
            </div>
            {Routes.map(({ Icon, ...route }, i) => {
                // eslint-disable-next-line
                if (route.hidden) return;

                return (
                    <Link
                        className={`flex hover:text-iconblue ${
                            location.pathname === route.path
                                ? "bg-specialgreen text-black"
                                : "text-cool-gray"
                        } hover:bg-specialpink p-2 items-center`}
                        key={i}
                        to={route.path}
                    >
                        <div className="mr-2">
                            <Icon />
                        </div>
                        <div className="text-sm">{route.name}</div>
                    </Link>
                );
            })}
            <div
                onClick={user.auth.logout}
                className="cursor-pointer text-sm flex text-cool-gray hover:text-iconblue hover:bg-specialblue p-2 items-center"
            >
                <FcUndo className="mr-2" /> Logout
            </div>
        </div>
    );
});

export default withUser(Sidebar);

import React from "react";

const modal = ({ reset, children }) => {
  return (
    <div className="z-50 py-2 min-h-screen w-full fixed inset-0 overflow-auto">
      <div
        className="tint h-full w-full fixed inset-0 bg-black bg-opacity-25"
        onClick={() => {
          reset();
        }}
      />
      {/* <div className="absolute w-full left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"> */}
      <div className="mt-38 absolute top-1/2 -translate-y-1/2 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm px-4">
        {children}
      </div>
    </div>
  );
};

export default modal
import React, { useState, useMemo } from "react";
import queries from "../helpers/queries";
import { useQuery } from "@apollo/react-hooks";
import usePagination from "../Hooks/usePagination";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import { Country, State } from "country-state-city";

const Packages = withRouter(({ history }) => {
    let CountryList = [
        Country.getCountryByCode("JP"),
        Country.getCountryByCode("KR"),
        Country.getCountryByCode("CN"),
        Country.getCountryByCode("TW"),
        Country.getCountryByCode("IN"),
        Country.getCountryByCode("TH"),
        Country.getCountryByCode("VN"),
    ];
    const { handleSubmit, errors } = useForm({
        defaultValues: {},
    });

    const filterSelectStyles = useMemo(
        () => ({
            control: (provided, state) => ({
                ...provided,
                borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                backgroundColor: "#fbfdfe",
                borderRadius: "0.5rem",
                padding: "0.20rem",
                "&:hover": {
                    borderColor: errors.area ? "#fc8181" : "#e2e8f0",
                },
            }),

            placeholder: (provided, state) => ({
                ...provided,
                color: "#a0aec0",
                fontSize: "0.75rem",
                fontWeight: "600",
            }),

            container: (provided, state) => ({
                ...provided,
                color: "#64748b",
                fontSize: "0.75rem",
                fontWeight: "600",
                width: "100%",
            }),
        }),
        [errors]
    );

    const updateFilters = (values) => {
        setFilters((prev) => ({
            ...prev,
            ...values,
            area: values.area ? values.area.value : "",
            state: values.state ? values.state.value : "",
        }));
        setPaginationState((prev) => ({ ...prev, page: 1 }));
    };

    const [packages, setPackages] = useState([]);

    const [paginationState, setPaginationState] = useState({
        limit: 10,
        page: 1,
    });

    const [filters, setFilters] = useState({
        area: null,
        state: null,
    });

    const { pagination } = usePagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : 0,
        totalPages: paginationState ? paginationState.totalPages : 0,
    });

    useQuery(queries.FETCH_TOURS, {
        variables: {
            limit: paginationState.limit,
            page: paginationState.page,
            area: filters.area ? filters.area : "",
            state: filters.state ? filters.state.value : "all",
            package: filters.package ? filters.package.value : "",
            country: filters.country ? filters.country.value : "",
        },
        fetchPolicy: "network-only",
        onCompleted: ({
            fetchPackagesAgent: { pagination, ...fetchPackagesAgent },
        }) => {
            setPaginationState(pagination);
            setPackages(fetchPackagesAgent.data);
        },
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const { data: projectsAllData } = useQuery(queries.FETCH_TOURS, {
        fetchPolicy: "network-only",
        variables: {
            limit: 9999,
            page: 1,
            state: "all",
        },
        // onCompleted: ({ fetchPackages: { data } }) => setPackages(data),
        onError: ({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                for (const graphQLError of graphQLErrors) {
                    console.log(graphQLError);
                }
            }

            if (networkError) {
                console.log(networkError);
            }
        },
    });

    const getAllCountries = () => {
        return CountryList.map((country) => ({
            value: country.isoCode, // Use isoCode as the value for the option
            label: country.name, // Use country name as the label for the option
        }));
    };

    const getStatesForCountry = (countryIsoCode) => {
        const states = State.getStatesOfCountry(countryIsoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name,
        }));
    };

    const clearFilters = () => {
        setFilters({
            country: null,
            state: null,
            package: null,
        });
    };

    return (
        <div className="flex h-full p-8 bg-black">
            <div className="bg-catskill rounded-md w-full">
                <form
                    className="relative px-24 flex flex-col lg:flex-row justify-center items-center mb-6 col-gap-4 row-gap-4 h-35vh"
                    style={{
                        backgroundImage:
                            "url(https://ik.imagekit.io/dnddecpho/longrich/bg_u3tmO2PJ3.jpeg?updatedAt=1712138629978)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    onSubmit={handleSubmit(updateFilters)}
                >
                    {/* Black overlay */}
                    <div className="absolute inset-0 bg-black opacity-50"></div>

                    <div className="lg:w-128 w-64 relative">
                        <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                            Country
                        </div>
                        <Select
                            styles={filterSelectStyles}
                            isSearchable={true}
                            options={getAllCountries()}
                            value={filters.country}
                            placeholder="Search by Country"
                            onChange={(val) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    country: val,
                                    // Reset state and package filters when country changes
                                    state: null,
                                    package: null,
                                }))
                            }
                        />
                    </div>
                    <div className="lg:w-128 w-64 relative">
                        <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                            State
                        </div>
                        <Select
                            styles={filterSelectStyles}
                            isSearchable={true}
                            options={
                                filters.country
                                    ? getStatesForCountry(filters.country.value)
                                    : []
                            }
                            value={filters.state}
                            placeholder="Search by State"
                            onChange={(val) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    state: val,
                                    // Reset package filter when state changes
                                    package: null,
                                }))
                            }
                        />
                    </div>
                    <div className="lg:w-128 w-64 relative">
                        <div className="text-white text capitalize text-left lg:text-left lg:block hidden">
                            Package
                        </div>
                        <Select
                            styles={filterSelectStyles}
                            placeholder="Search by Packages Name"
                            isSearchable={true}
                            options={
                                filters.state && filters.country // Check if both state and country are defined
                                    ? projectsAllData
                                        ? projectsAllData.fetchPackagesAgent.data
                                              .filter(
                                                  (pack) =>
                                                      pack.country ===
                                                          filters.country
                                                              .value &&
                                                      pack.state ===
                                                          filters.state.value
                                              )
                                              .map((pack) => ({
                                                  label: pack.name,
                                                  value: pack._id,
                                              }))
                                        : []
                                    : filters.country && !filters.state // Check if only country is defined and state is not defined
                                    ? projectsAllData
                                        ? projectsAllData.fetchPackagesAgent.data
                                              .filter(
                                                  (pack) =>
                                                      pack.country ===
                                                      filters.country.value
                                              )
                                              .map((pack) => ({
                                                  label: pack.name,
                                                  value: pack._id,
                                              }))
                                        : []
                                    : projectsAllData // If neither country nor state is defined, show all packages
                                    ? projectsAllData.fetchPackagesAgent.data.map(
                                          (pack) => ({
                                              label: pack.name,
                                              value: pack._id,
                                          })
                                      )
                                    : []
                            }
                            value={filters.package}
                            onChange={(val) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    package: val,
                                }))
                            }
                        />
                    </div>
                    <div className="lg:w-128 w-64 relative">
                        {/* Button with Package label */}
                        <button
                            className="bg-yellow-400 capitalize font-semibold text-left mt-6 lg:text-left lg:block hidden px-6 py-2 rounded-lg border border-black hover:bg-black hover:text-yellow-400"
                            onClick={() => clearFilters()}
                        >
                            Reset Filters
                        </button>
                    </div>
                </form>

                <div className="lg:p-6 lg:px-24 p-2">
                    <div className="grid grid-cols-1 gap-4 text-white">
                        {packages
                            ? packages.map((item, i) => {
                                  const selectedCountry = CountryList.find(
                                      (outlet) =>
                                          outlet.isoCode === item.country
                                  );

                                  const states = State.getAllStates();
                                  const selectedStates = states.find(
                                      (outlet) => outlet.isoCode === item.state
                                  );
                                  return (
                                      <div
                                          className="block border border-black"
                                          key={i}
                                      >
                                          <div
                                              className="grid lg:grid-cols-2"
                                              key={i}
                                          >
                                              <div className="h-full relative ">
                                                  <div className="absolute top-30 left-10 z-2 text-white">
                                                      <div
                                                          className={`bg-iconpink my-2 ml-2 p-2 hidden lg:flex lg:w-40 text-catskill border border-black rounded-lg flex justify-center`}
                                                      >
                                                          <div className="">
                                                              <span className="capitalize">
                                                                  SAVE RM{" "}
                                                                  {item.savings}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <img
                                                      className="h-full w-full object-cover col-span-4"
                                                      src={item.image}
                                                      alt={""}
                                                  />
                                              </div>
                                              <div className="flex justify-center border-solid border border-black flex-col col-span-1">
                                                  <div className="w-full rounded  bg-cool-gray">
                                                      <div className="bg-catskill rounded-lg flex flex-col p-8">
                                                          <div className="flex flex-col lg:flex-col">
                                                              <div className="my-1 text-icongreen text-xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.name
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="my-1 text-icongreen text-xl capitalize">
                                                                  <strong>
                                                                      {
                                                                          item.cnName
                                                                      }
                                                                  </strong>{" "}
                                                              </div>
                                                              <div className="my-1 text-icongreen text-sm capitalize">
                                                                  {selectedCountry
                                                                      ? selectedCountry.name
                                                                      : ""}
                                                              </div>
                                                              <div className="my-1 text-icongreen text-sm capitalize">
                                                                  {selectedStates
                                                                      ? selectedStates.name
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="my-1 mb-4 text-icongreen text-sm capitalize">
                                                              <div>
                                                                  <strong>
                                                                      Departure
                                                                      Dates
                                                                  </strong>
                                                              </div>
                                                              <div className="lg:flex">
                                                                  {item.tours
                                                                      ? item.tours
                                                                            .slice() // Create a shallow copy of the tours array to avoid mutating the original array
                                                                            .sort(
                                                                                (
                                                                                    a,
                                                                                    b
                                                                                ) =>
                                                                                    parseInt(
                                                                                        a.tourDate
                                                                                    ) -
                                                                                    parseInt(
                                                                                        b.tourDate
                                                                                    )
                                                                            ) // Sort the tours based on tourDate
                                                                            .map(
                                                                                (
                                                                                    tour,
                                                                                    i
                                                                                ) => (
                                                                                    <div
                                                                                        key={
                                                                                            i
                                                                                        }
                                                                                        className="mt-2 my-2 px-3 py-4 text-sm cursor-pointer border border-black capitalize bg-icongreen text-white hover:bg-yellow-400 hover:text-black font-semibold"
                                                                                        onClick={() => {
                                                                                            history.push(
                                                                                                `/packages/${item._id}`,
                                                                                                tour
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <div className="text-center">
                                                                                            {moment(
                                                                                                parseInt(
                                                                                                    tour.tourDate
                                                                                                )
                                                                                            ).format(
                                                                                                "DD-MM-YYYY"
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                      : ""}
                                                              </div>
                                                          </div>
                                                          <hr className="my-3"></hr>
                                                          <div className="grid grid-cols-1 lg:grid-cols-2">
                                                              <div className="my-1 mb-4 text-icongreen text-sm capitalize">
                                                                  <div className="">
                                                                      <strong>
                                                                          Adult
                                                                          Price
                                                                          from
                                                                      </strong>
                                                                      <br></br>
                                                                      RM{" "}
                                                                      {item
                                                                          .tours[0]
                                                                          ? item
                                                                                .tours[0]
                                                                                .adultPrice
                                                                          : "-"}
                                                                      * per
                                                                      person
                                                                  </div>
                                                              </div>
                                                              <div className="my-1 mb-4 text-icongreen text-sm capitalize">
                                                                  <div className="">
                                                                      <strong>
                                                                          Child
                                                                          Price
                                                                          from
                                                                      </strong>
                                                                      <br></br>
                                                                      RM{" "}
                                                                      {item
                                                                          .tours[0]
                                                                          ? item
                                                                                .tours[0]
                                                                                .childNoBedPrice
                                                                          : "-"}
                                                                      * per
                                                                      person
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <hr />

                                                          <div className="lg:flex">
                                                              {item.tours[0] !==
                                                              undefined ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          history.push(
                                                                              `/packages/${item._id}`
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:bg-black bg-iconblue cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      View Tours
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              {item.itinerary ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.itinerary,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4 w-40 text-sm text-catskill hover:bg-black bg-icongreen cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Itinery
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                              {item.video ? (
                                                                  <div
                                                                      onClick={() =>
                                                                          window.open(
                                                                              item.video,
                                                                              "_blank"
                                                                          )
                                                                      }
                                                                      className="mt-2 mr-2 my-2 p-1 py-4  w-40 text-sm text-catskill hover:bg-black bg-iconpink cursor-pointer rounded-lg flex justify-center capitalize"
                                                                  >
                                                                      Video
                                                                  </div>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </div>
                </div>
                <div className="p-4 bg-cool-gray border-t font-semibold text-lynch text-xs">
                    <ul className="flex justify-end pr-5">
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                if (paginationState.page !== 1) {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: prev.page - 1,
                                    }));
                                }
                            }}
                        >
                            Previous
                        </li>
                        {paginationState.page
                            ? pagination.map((page, i) => (
                                  <li
                                      className={`p-2 px-4 cursor-pointer ${
                                          page === paginationState.page
                                              ? "bg-darkblue text-white"
                                              : "text-xs"
                                      }`}
                                      key={i}
                                      onClick={() =>
                                          setPaginationState((prev) => ({
                                              ...prev,
                                              page: page,
                                          }))
                                      }
                                  >
                                      {page}
                                  </li>
                              ))
                            : ""}
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                if (
                                    paginationState.page !==
                                    paginationState.totalPages
                                ) {
                                    setPaginationState((prev) => ({
                                        ...prev,
                                        page: prev.page + 1,
                                    }));
                                }
                            }}
                        >
                            Next
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default Packages;
